import React from 'react';
// import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import {
  Banner,
} from 'components';
import { BASE_URL } from 'config';

// const showdown = require('showdown');

// const converter = new showdown.Converter();

const EarlyBirdSnowPromo = () => (
  <Layout page="about/early-bird-snow-promotion">

    <Helmet
      title="2019 Early Bird Special Offer | NZ Snow Tours & Packages | Haka Tours"
      meta={[
        {
          name: 'description', content: 'Save $50 on any Snow Tour and go in the draw to Ski for free. Simply book any Haka Snow Tour by 30 April 2019.',
        },
        { name: 'og:title', content: '2019 Early Bird Special Offer | NZ Snow Tours & Packages | Haka Tours' },
        {
          name: 'og:description', content: 'Save $50 on any Snow Tour and go in the draw to Ski for free. Simply book any Haka Snow Tour by 30 April 2019.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/early-bird-snow-promotion/` }]}
    />

    <Banner backgroundImage="https://hakatours-staging.imgix.net/haka-early-bird-promotion-2.jpg?h=490&bm=screen&fit=crop&auto=format">
      <h1 className="c-heading c-heading--h0">Early Bird Snow Promotion</h1>
    </Banner>
    <section className="l-section">
      <div className="l-container l-container--small">
        <h1 className="c-heading c-heading--h3">Get $50 off any Snow Tour if booked between now and 30 April 2019. PLUS, go in the draw to ‘Ski for Free’!</h1>
        <p><b>How do I get $50 off my Snow tour?</b></p>
        <p>The discount has already been applied! Simply start booking any snow tour and you’ll see the price is now $50 less.</p>
        <p><b>How can I SKI for FREE?</b></p>
        <p>
          All you need to do is book a Snow tour before 30 April 2019 and you automatically go into the draw.
          One lucky winner will get the cost of their base tour refunded. Competition ends on 30 April 2019, and the winner will be randomly selected by 10 May 2019.
        </p>
        <p><b>Our Snow Tours</b></p>
        <p>
          <ul>
            <li><a href="https://hakatours.com/new-zealand-snow-tours/snow-explorer?HTPC=50SNOW">5 Day South Island Snow Explorer</a></li>
            <li><a href="https://hakatours.com/new-zealand-snow-tours/south-island-snow-safari?HTPC=50SNOW">7 Day South Island Snow Safari</a></li>
            <li><a href="https://hakatours.com/new-zealand-snow-tours/south-island-heliski-tour?HTPC=50SNOW">7 Day Ultimate Heli Tour</a></li>
            <li><a href="https://hakatours.com/new-zealand-snow-tours/south-island-club-fields?HTPC=50SNOW">12 Day South Island Snow Odyssey</a></li>
          </ul>
        </p>
        <p><b>‘Save $50’ Terms & Conditions:</b></p>
        <p>Cannot be combined with any other offer.</p>
        <p>Cannot be applied to bookings made before 10 April 2019.</p>
        <br />
        <p><b>‘Ski for FREE’ Terms & Conditions</b></p>
        <p>Cannot be combined with any other offer.</p>
        <p>Cannot be applied to bookings made before 10 April 2019.</p>
        <p>A tour must be paid for <u>in full</u> by 30 April 2019 to be considered a valid entry.</p>
        <p>The winner will receive a refund of their base tour cost upon completing their snow tour.</p>
      </div>
    </section>
  </Layout>
);

// EarlyBirdSnowPromo.propTypes = {
//   data: PropTypes.object.isRequired,
// };

export default EarlyBirdSnowPromo;

// export const query = graphql`
//   query {
//     allStaticPages(
//       filter: {
//         slug: { eq: "early-bird-snow-promotion" },
//       }
//     ) {
//       edges {
//         node {
//           id
//           api_id
//           slug
//           content_markdown
//         }
//       }
//     }
//   }
// `;
